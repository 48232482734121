<template>
  <div class="pre-order">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right lazy>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Xuất Excel
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="importExcel">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Nhập excel
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <BDropdownCustom
              v-model="apiParams.storeIds"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :searchable="true"
              :matchKey="['name', 'shortName']"
            />
          </b-col>
          <b-col>
            <div class="d-flex">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.fromDate"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.toDate"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>

          <b-col>
            <BDropdownCustom
              v-model="apiParams.formIds"
              :options="optionForms"
              :placeholder="'chương trình'"
              :searchable="true"
              :matchKey="['name']"
            />
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="apiParams.status"
              :options="optionStatus"
              :placeholder="'trạng thái'"
              :searchable="true"
              :matchKey="['name', 'shortName']"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.partnerPaymentStatus"
              :options="paymentStatus"
              class="select-style"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Id đơn hàng"
              v-model="apiParams.orderId"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="apiParams.createdByName"
              :suggestions="optionEmployees"
              :suggestionName="'fullName'"
              :placeholder="'nhân viên CSKH'"
              :limit="100"
              @select="onSelected($event, 'Employee')"
              @change="onInputed($event, 'Employee')"
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tên hoặc SĐT khách hàng"
              v-model="apiParams.customerSearch"
              v-on:keyup.enter="onFilter()"
              v-focus
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo sản phẩm"
              v-model="apiParams.productSearch"
              v-on:keyup.enter="onFilter()"
            />
          </b-col>

          <b-col>
            <b-input
              size="sm"
              placeholder="Ghi chú"
              v-model="apiParams.customerAddress"
              v-on:keyup.enter="onFilter()"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Id đơn đặt trước"
              v-model="apiParams.id"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.wantUpgrade"
              :options="optionsWantUpgrade"
              class="select-style"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="preOrders"
            :fields="preOrdersFields"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
          >
            <template #cell(createdAt)="data">
              <span class="d-block mb-2">{{ data.item.createdAt }}</span>
              <span class="d-block">{{ data.item.createdByName }}</span>
              <b class="text-left pull-left" style="font-size: 0.8rem">{{
                data.item.storeName
              }}</b>
            </template>
            <template #cell(id)="data">
              <span
                @click="editPreOrder(data.item.id)"
                class="
                  text-primary
                  cursor-pointer
                  d-block
                  mb-1
                  font-weight-bolder
                "
                >{{ data.item.id }}</span
              >
              <span
                v-b-tooltip.hover
                title="Thanh toán thành công"
                v-if="data.item.partnerPaymentStatus === 1"
              >
                <i
                  class="fa fa-check-circle text-success"
                  aria-hidden="true"
                ></i>
              </span>
              <span
                v-b-tooltip.hover
                title="Thanh toán thất bại"
                v-if="data.item.partnerPaymentStatus === 2"
              >
                <i
                  class="fa fa-times-circle text-danger"
                  aria-hidden="true"
                ></i>
              </span>
            </template>
            <template #cell(formId)="data">
              <b
                class="text-primary cursor-pointer"
                @click="filterByFormId(data.item.formId)"
              >
                {{ data.item.formName }}
              </b>
            </template>
            <template #cell(productName)="data">
              <span v-text="formatProductName(data.item)"></span>
              <div class="mt-2 d-table" v-if="data.item.product">
                <div class="d-table-row">
                  <div class="d-table-cell pl-3 pr-2">
                    <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
                  </div>
                  <div class="d-table-cell">
                    <span v-if="data.item.product.barCode">
                      {{ data.item.product.barCode }}
                    </span>
                    <span class="d-block" v-if="data.item.product.productCode"
                      >({{ data.item.product.productCode }})</span
                    >
                    <span class="d-block font-weight-bolder">
                      {{ data.item.product.productName }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <span
                v-text="getStatusName(data.item.status)"
                style="width: max-content"
                v-bind:class="{
                  'badge badge-warning text-white': data.item.status === 1,
                  'badge badge-primary text-white': data.item.status === 2,
                  'badge badge-danger': data.item.status === 3,
                  'badge badge-light text-dark': data.item.status === 4,
                  'badge badge-info': data.item.status === 5,
                  'badge badge-light': data.item.status === 6,
                  'badge badge-dark':
                    data.item.status === 7 ||
                    data.item.status === 8 ||
                    data.item.status === 10 ||
                    data.item.status === 11,
                  'badge badge-success': data.item.status === 9,
                }"
              ></span>
              <div class="mt-2">
                <router-link
                  :to="{
                    name: 'edit-order',
                    params: { id: data.item.orderId },
                  }"
                  class="font-weight-bolder"
                  v-b-tooltip.hover.left
                  title="Đơn hàng"
                >
                  {{ data.item.orderId }}
                </router-link>
                <p v-if="data.item.orderWarning" class="mt-2">
                  <i
                    class="fa fa-exclamation-triangle text-warning"
                    v-b-tooltip.hover.bottom
                    :title="data.item.orderWarningName"
                    aria-hidden="true"
                  ></i>
                </p>
              </div>
            </template>
            <template #cell(customerName)="data">
              <b>{{ data.item.customerName }}</b>
              <span
                class="cursor-pointer d-block mb-1"
                @click="editUser(data.item)"
              >
                {{ data.item.customerMobile }}
              </span>
              <div
                v-if="
                  data.item.customerAddress &&
                  data.item.customerAddress.trim().length > 0
                "
                class="mb-1"
              >
                <b>ĐC:</b>
                {{ data.item.customerAddress }}
              </div>
            </template>
            <template #cell(notes)="data">
              <span
                v-if="
                  data.item.installMoneyCode &&
                  data.item.installMoneyCode.trim().length > 0
                "
                class="d-block"
              >
                <b class="d-block">Mã thanh toán:</b>
                {{ data.item.installMoneyCode }}
              </span>
              <p
                v-if="
                  data.item.installMoneyCode &&
                  data.item.installMoneyCode.trim().length > 0
                "
                class="dashed"
              ></p>
              <span class="pre-line">{{ data.item.notes }}</span>
            </template>
            <template #cell(actions)="data">
              <b-dropdown size="sm" no-caret right lazy>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-settings pr-0"
                  ></i>
                </template>
                <b-dropdown-item @click="editPreOrder(data.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchPreOrders"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <EditPreOrder
      :params="preOrder"
      :stores="optionStores"
      v-on:onRefresh="onRefresh"
    />
    <PreOrderExcel :param="apiParams" />
    <PreOrderImportExcel v-on:onRefresh="fetchPreOrders" />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import {
  makeToastFaile,
  formatDate,
  convertPrice,
  removeAccents,
  removeSelectAllOptions,
  selectAllOptions,
} from '@/utils/common';
import {
  PREORDER_STATUS,
  TIME_OUT,
  PAGE,
  PAYMENT_STATUS,
  TIME_TRIGGER,
  CONSTANT_WANT_UPGRADE,
} from '@/utils/constants';
import EditPreOrder from '../../components/pre-order/EditPreOrder';
import { cloneDeep, keyBy, debounce, map, find, assign } from 'lodash';

import PreOrderExcel from '../../components/pre-order/PreOrderExcel';
import PreOrderImportExcel from '../../components/pre-order/PreOrderImportExcel';
import BDropdownCustom from '../../base/bootstrap/BDropdownCustom.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import timeUtils from '@/utils/date';

export default {
  data() {
    return {
      dpConfigs: null,

      apiParams: {
        id: null,
        formIds: [],
        // formName: [],
        customerSearch: null,
        productSearch: null,
        status: [],
        notes: null,
        storeIds: [],
        fromDate: null,
        toDate: null,
        partnerPaymentStatus: null,
        createdBy: null,
        orderId: null,
        wantUpgrade: null,
      },
      title: '',
      preOrders: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      preOrdersFields: [
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'id',
          label: 'ID',
          tdClass: 'text-center',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: {
            width: '13%',
          },
          tdClass: 'text-wrap',
        },
        {
          key: 'formId',
          label: 'Chương trình',
        },
        {
          key: 'productName',
          label: 'Máy khách muốn sở hữu',
        },
        {
          key: 'notes',
          label: 'Ghi chú',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      preOrder: {},
      optionForms: [],
      filteredEmployees: [],
      paymentStatus: [],
      optionsWantUpgrade: [],
      optionStores: [],
      optionOrigin: {
        employees: [],
        forms: [],
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      optionStatus: [],
      filteredForm: [],
      filteredStore: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    EditPreOrder,
    DatePicker,
    PreOrderExcel,
    PreOrderImportExcel,
    BDropdownCustom,
    Autosuggest,
  },
  computed: {
    optionEmployees() {
      return cloneDeep(this.filteredEmployees);
    },
  },
  mounted() {
    this.title = 'Danh sách đơn hàng đặt trước';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.onInitData();
    this.onFilter();
    this.fetchStores();
    this.fetchEmployees();
    this.fetchPreOrdersForm();
    this.dpConfigs = timeUtils.DP_CONFIG;
  },
  methods: {
    formatDate,
    convertPrice,
    onInitData() {
      this.paymentStatus = cloneDeep(PAYMENT_STATUS);
      this.optionsWantUpgrade = cloneDeep(CONSTANT_WANT_UPGRADE);

      const status = cloneDeep(
        PREORDER_STATUS.filter((x) => x.checked === false),
      );
      this.optionStatus = [...status];
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    formatProductName(data) {
      return [data.productName, data.size, data.color]
        .filter((item) => item)
        .join(' - ');
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Form':
          this.searchForm(textInput);
          break;
        case 'Employee': {
          if (!textInput || !textInput.trim().length) {
            this.apiParams.createdByName = null;
            this.apiParams.createdBy = null;
          }
          this.debounceInput(textInput, this.searchEmployeeAPI);
          break;
        }
        default:
          break;
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'Form': {
          const { items, selectItems } = selectAllOptions(
            this.optionForms,
            this.apiParams.formIds,
            option,
          );
          this.filteredForm = items;
          this.apiParams.formIds = selectItems;
          break;
        }
        case 'Employee':
          this.apiParams.createdBy = option.item.id;
          break;
        default:
          break;
      }
    },
    onRemoved(option, type) {
      switch (type) {
        case 'Form': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionForms,
            this.apiParams.formIds,
            option,
          );

          this.filteredForm = items;
          this.apiParams.formIds = selectItems;
          break;
        }
        default:
          break;
      }
    },
    filterByFormId(formId) {
      const exist = this.optionForms.find((prop) => prop.id === formId);
      if (!exist) {
        this.fetchPreOrdersFormById(formId).then((response) => {
          if (response.status) {
            const form = response.data.data[0];
            const option = {
              id: form.id,
              name: form.name,
              checked: false,
            };
            this.apiParams.formIds = [];
            this.apiParams.formIds.push(option);
            this.optionForms.push(option);
            this.filteredForm.push(option);
            this.onFilter();
          }
        });
      } else {
        const option = {
          id: exist.id,
          name: exist.name,
          checked: false,
        };
        this.apiParams.formIds = [];
        this.apiParams.formIds.push(option);
        this.onFilter();
      }
    },
    getStatusName(id) {
      const preOrderStatus = PREORDER_STATUS.filter((status) => status.id);
      const picked = keyBy(preOrderStatus, 'id')[id];
      return picked ? picked.name : '';
    },
    downloadExcel() {
      this.$bvModal.show('modal-pre-order-excel');
    },
    editPreOrder(id) {
      const preOrder = this.preOrders.find((p) => p.id === id);

      this.preOrder = preOrder;
      this.preOrder.orderId = preOrder.orderId;
      this.preOrder.orderWarning = preOrder.orderWarning;

      const products = [
        {
          productName: preOrder.productName,
          productPrice: preOrder.productPrice,
          size: preOrder.size,
          color: preOrder.color,
        },
      ];

      this.preOrder.products = products;
      this.$bvModal.show('editPreOrder-pre-order');
    },
    onFilter() {
      this.onRefreshPageOne();
      this.fetchPreOrders();
    },
    onRefreshPageOne() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-pre-order',
      });
    },
    onRefresh() {
      this.fetchPreOrders();
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    editUser: function (item) {
      this.$router.push({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerMobile },
      });
    },
    getParamFilters() {
      // //get danh sách status filter
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const apiParams = cloneDeep(this.apiParams);

      apiParams.status = apiParams.status.map((item) => item.id);

      apiParams.storeIds = apiParams.storeIds.map((item) => item.id);

      apiParams.formIds = apiParams.formIds.map((item) => item.id);
      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        ...apiParams,
        fromDate: this.apiParams.fromDate
          ? moment(apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
        toDate: this.apiParams.toDate
          ? moment(apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
      };
    },
    searchForm(textInput) {
      let options = cloneDeep(this.optionForms);
      if (!textInput || !textInput.trim().length) {
        this.filteredForm = map(options, (obj) => {
          return assign(obj, find(this.filteredForm, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredForm, { id: obj.id }));
      });

      this.filteredForm = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchEmployeeAPI(textInput) {
      if (!textInput || !textInput.trim().length) {
        return;
      }

      this.filteredEmployees = this.fitlerOptionsBy(
        this.optionOrigin.employees,
        textInput,
        'fullName',
        10,
      );
    },
    searchFormAPI(textInput) {
      if (!textInput || !textInput.trim().length) {
        return;
      }

      this.optionForms = this.fitlerOptionsBy(
        this.optionOrigin.forms,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchPreOrders: function () {
      if (!this.onLoading) {
        this.onLoading = true;
        this.preOrders = [];
        const params = this.getParamFilters();

        ApiService.query('pre-orders', { params }, { timeout: TIME_OUT })
          .then((response) => {
            const dataset = response.data.data;
            this.totalItem = dataset.total;
            this.numberOfPage = dataset.total_page;
            this.preOrders = dataset.data;
            this.onLoading = false;
          })
          .catch((error) => {
            this.onLoading = false;
            this.$nprogress.done();
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi',
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          });
      }
    },
    fetchPreOrdersFormById: function (formId) {
      return ApiService.query('pre-orders/form', {
        params: { formId: formId, page: 1, size: 1 },
      }).then((response) => response.data);
    },
    fetchPreOrdersForm: function () {
      ApiService.query('pre-orders/form', {
        params: { size: 10000, page: PAGE },
      }).then((response) => {
        const dataset = response.data.data;
        const forms = [...dataset.data];
        this.optionForms = forms;
        this.filteredForm = cloneDeep(forms);
        this.optionOrigin.forms = cloneDeep(forms);
      });
    },
    fetchStores: async function () {
      const DEFAULT_COMPANY_ID = 3;
      ApiService.query(`stores/getStores`, {
        params: {
          companyId: DEFAULT_COMPANY_ID,
        },
      }).then((response) => {
        const stores = response.data.data;
        this.optionStores = [...stores];
        this.filteredStore = cloneDeep(this.optionStores);
      });
    },
    fetchEmployees: async function () {
      ApiService.get('employees/getAll').then((response) => {
        const employees = response.data.data;
        this.filteredEmployees = employees;
        this.optionOrigin.employees = employees;
      });
    },
    importExcel: function () {
      this.$bvModal.show('modal-pre-order-import');
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.cursor-pointer:hover {
  text-decoration: underline;
}
input.form-control {
  border: 1px solid #ced4da;
}

.pre-order {
  .vue-treeselect__multi-value-item-container {
    display: none;
  }
  
  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 2px;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table thead tr > th {
    text-align: center;
  }

  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #fff;
    background-color: #fff;
  }
  .pre-line {
    white-space: pre-line;
  }
}
</style>
